import React, { useState, useRef, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus, vs } from 'react-syntax-highlighter/dist/esm/styles/prism';

function App() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [model, setModel] = useState('anthropic/claude-3.5-sonnet:beta');
  const [isLoading, setIsLoading] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editContent, setEditContent] = useState('');
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme === 'dark';
  });
  const chatEndRef = useRef(null);

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  useEffect(() => {
    document.body.className = isDarkMode ? 'dark' : 'light';
    localStorage.setItem('theme', isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);

  const getShortModelName = (fullName) => {
    switch (fullName) {
      case 'anthropic/claude-3-opus:beta':
        return 'Claude 3 Opus';
      case 'anthropic/claude-3.5-sonnet:beta':
        return 'Claude 3.5 Sonnet';
      case 'openai/gpt-4o':
        return 'GPT-4o';
      case 'google/gemini-pro-1.5':
        return 'Gemini Pro 1.5';
      default:
        return fullName;
    }
  };

  const getModelIcon = (fullName) => {
    if (fullName.startsWith('anthropic/')) {
      return "https://qph.cf2.poecdn.net/main-thumb-pb-1019-200-ecyfizaydihfkxfwhwjlruyjdyoxengr.jpeg";
    } else if (fullName.startsWith('openai/')) {
      return "https://qph.cf2.poecdn.net/main-thumb-pb-3015-200-ivodfqemfvztmvgafhdouijhknthkvmp.jpeg";
    } else if (fullName.startsWith('google/')) {
      return "https://qph.cf2.poecdn.net/main-thumb-pb-6008-200-nxbknipknljbdzryoyykfbeoigkcnlnz.jpeg";
    }
    return "";
  };

const handleSubmit = async (e) => {
  e.preventDefault();
  if (!input.trim() || isLoading) return;

  const userMessage = { role: 'user', content: input };
  const newMessages = [...messages, userMessage];
  setMessages(newMessages);
  setInput('');
  setIsLoading(true);

  try {
    const response = await fetch("https://api.delz.workers.dev/api/chat/completions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        model: model,
        messages: [
          { role: 'system', content: 'You are a helpful assistant.' },
          ...newMessages
        ],
        provider: { data_collection: "deny" },
        stream: true,
      })
    });

    if (!response.ok) throw new Error('Network response was not ok');

    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let assistantMessage = { role: 'assistant', content: '', model: model };
    let buffer = '';

    setMessages(prevMessages => [...prevMessages, assistantMessage]);

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;

      buffer += decoder.decode(value, { stream: true });
      const lines = buffer.split('\n');
      buffer = lines.pop() || '';

      for (const line of lines) {
        if (line.startsWith('data: ')) {
          const data = line.slice(6);
          if (data === '[DONE]') continue;
          
          try {
            const parsed = JSON.parse(data);
            const content = parsed.choices[0]?.delta?.content || '';
            assistantMessage.content += content;
            setMessages(prevMessages => {
              const newMessages = [...prevMessages];
              newMessages[newMessages.length - 1] = { 
                ...assistantMessage,
                content: assistantMessage.content
              };
              return newMessages;
            });
          } catch (error) {
            console.error('Error parsing SSE data:', error);
          }
        }
      }
    }

    // Process any remaining data in the buffer
    if (buffer.startsWith('data: ')) {
      const data = buffer.slice(6);
      if (data !== '[DONE]') {
        try {
          const parsed = JSON.parse(data);
          const content = parsed.choices[0]?.delta?.content || '';
          assistantMessage.content += content;
          setMessages(prevMessages => {
            const newMessages = [...prevMessages];
            newMessages[newMessages.length - 1] = { 
              ...assistantMessage,
              content: assistantMessage.content
            };
            return newMessages;
          });
        } catch (error) {
          console.error('Error parsing final SSE data:', error);
        }
      }
    }

  } catch (error) {
    console.error('Error:', error);
    setMessages(prevMessages => [
      ...prevMessages,
      { role: 'system', content: 'An error occurred while fetching the response. Please try again.' }
    ]);
  } finally {
    setIsLoading(false);
  }
};

  const CustomCodeBlock = ({language, value}) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
      navigator.clipboard.writeText(value).then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      });
    };

    return (
      <div className={`rounded-md my-4 ${isDarkMode ? 'bg-gray-800' : 'bg-gray-100'}`}>
        <div className={`flex items-center justify-between px-4 py-2 border-b ${isDarkMode ? 'border-gray-700' : 'border-gray-300'}`}>
          <span className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>{language}</span>
          <button 
            onClick={handleCopy}
            className={`text-sm ${isDarkMode ? 'text-gray-400 hover:text-white' : 'text-gray-600 hover:text-black'}`}
          >
            {copied ? 'Copied!' : 'Copy'}
          </button>
        </div>
        <SyntaxHighlighter 
          language={language} 
          style={isDarkMode ? vscDarkPlus : vs}
          customStyle={{
            margin: 0,
            padding: '1rem',
            background: 'transparent',
          }}
        >
          {value}
        </SyntaxHighlighter>
      </div>
    );
  };

  const handleEdit = (index) => {
    setEditingIndex(index);
    setEditContent(messages[index].content);
  };

  const handleSave = () => {
    setMessages(prevMessages => {
      const newMessages = [...prevMessages];
      newMessages[editingIndex] = { ...newMessages[editingIndex], content: editContent };
      return newMessages;
    });
    setEditingIndex(null);
  };

  const handleDelete = (index) => {
    setMessages(prevMessages => prevMessages.filter((_, i) => i !== index));
  };

  const handleCopy = (content) => {
    navigator.clipboard.writeText(content);
  };

  const toggleTheme = () => {
    setIsDarkMode(prev => !prev);
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  return (
    <div className={`flex flex-col h-screen ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-black'}`}>
      <header className={`${isDarkMode ? 'bg-gray-800' : 'bg-blue-600'} text-white p-4 flex justify-between items-center`}>
        <h1 className="text-2xl font-bold">AI Chat App</h1>
        <button onClick={toggleTheme} className="p-2 rounded-full hover:bg-opacity-80">
          {isDarkMode ? '☀️' : '🌙'}
        </button>
      </header>
      <main className="flex-grow p-4 overflow-auto">
        <div className="max-w-4xl mx-auto space-y-4">
          {messages.map((message, index) => (
            <div key={index} className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'} mb-4`}>
              <div className={`max-w-[90%] p-4 rounded-lg ${
                message.role === 'user' 
                  ? isDarkMode ? 'bg-blue-700' : 'bg-blue-500 text-white'
                  : isDarkMode ? 'bg-gray-800' : 'bg-white shadow-md'
              }`}>
                <div className="flex items-center mb-2">
                  {message.role !== 'user' && (
                    <img src={getModelIcon(message.model)} alt={message.model} className="w-6 h-6 mr-2 rounded-full" />
                  )}
                  <div className="text-sm font-bold">
                    {message.role === 'user' ? 'You' : `AI [${getShortModelName(message.model)}]`}
                  </div>
                </div>
                {editingIndex === index ? (
                  <div className="mt-2">
                    <textarea
                      value={editContent}
                      onChange={(e) => setEditContent(e.target.value)}
                      className={`w-full p-2 border rounded-md ${isDarkMode ? 'bg-gray-700 text-white' : 'bg-white text-black'}`}
                      rows="8"
                      style={{ minHeight: '150px', minWidth: '300px' }}
                    />
                    <div className="mt-2 flex justify-end space-x-2">
                      <button
                        onClick={() => setEditingIndex(null)}
                        className={`px-4 py-2 rounded-md ${isDarkMode ? 'bg-gray-600 hover:bg-gray-500' : 'bg-gray-300 hover:bg-gray-400'}`}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleSave}
                        className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    <ReactMarkdown
                      children={message.content}
                      components={{
                        code({node, inline, className, children, ...props}) {
                          const match = /language-(\w+)/.exec(className || '')
                          return !inline && match ? (
                            <CustomCodeBlock
                              language={match[1]}
                              value={String(children).replace(/\n$/, '')}
                            />
                          ) : (
                            <code className={`rounded px-1 py-0.5 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'}`} {...props}>
                              {children}
                            </code>
                          )
                        },
                        p: ({node, ...props}) => <p className="mb-2" {...props} />,
                        h1: ({node, ...props}) => <h1 className="text-2xl font-bold mb-2" {...props} />,
                        h2: ({node, ...props}) => <h2 className="text-xl font-bold mb-2" {...props} />,
                        h3: ({node, ...props}) => <h3 className="text-lg font-bold mb-2" {...props} />,
                        ul: ({node, ...props}) => <ul className="list-disc pl-5 mb-2 space-y-1" {...props} />,
                        ol: ({node, ...props}) => <ol className="list-decimal pl-5 mb-2 space-y-1" {...props} />,
                        li: ({node, ...props}) => <li className="mb-1" {...props} />,
                      }}
                    />
                    <div className="mt-2 flex space-x-2">
                      <button
                        onClick={() => handleEdit(index)}
                        className="px-2 py-1 text-xs bg-blue-500 text-white rounded hover:bg-blue-600"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleDelete(index)}
                        className="px-2 py-1 text-xs bg-red-500 text-white rounded hover:bg-red-600"
                      >
                        Delete
                      </button>
                      <button
                        onClick={() => handleCopy(message.content)}
                        className="px-2 py-1 text-xs bg-gray-500 text-white rounded hover:bg-gray-600"
                      >
                        Copy
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          ))}
          {isLoading && (
            <div className="text-gray-500 italic">AI is typing...</div>
          )}
          <div ref={chatEndRef} />
        </div>
      </main>
      <footer className={`p-4 ${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-t border-gray-200'}`}>
        <div className="max-w-4xl mx-auto">
          <div className="mb-4">
            <label htmlFor="model-select" className={`block text-sm font-medium mb-2 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
              Select AI Model
            </label>
            <select
              id="model-select"
              value={model}
              onChange={(e) => setModel(e.target.value)}
              className={`w-full p-2 border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 ${
                isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'bg-white border-gray-300 text-black'
              }`}
            >
              <option value="anthropic/claude-3.5-sonnet:beta">Claude 3.5 Sonnet</option>
              <option value="anthropic/claude-3-opus:beta">Claude 3 Opus</option>
              <option value="openai/gpt-4o">GPT-4o</option>
              <option value="google/gemini-pro-1.5">Gemini Pro 1.5</option>
            </select>
          </div>
          <form onSubmit={handleSubmit} className="flex">
            <textarea
              value={input}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              className={`flex-grow p-2 border rounded-l-md focus:ring-blue-500 focus:border-blue-500 ${
                isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'bg-white border-gray-300 text-black'
              }`}
              placeholder="Type your message... (Shift+Enter for new line)"
              disabled={isLoading}
              rows="3"
              style={{ resize: 'none' }}
            />
            <button
              type="submit"
              className={`p-2 rounded-r-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 ${
                isDarkMode
                  ? 'bg-blue-600 text-white hover:bg-blue-700'
                  : 'bg-blue-500 text-white hover:bg-blue-600'
              }`}
              disabled={isLoading}
            >
              {isLoading ? 'Sending...' : 'Send'}
            </button>
          </form>
        </div>
      </footer>
    </div>
  );
}

export default App;